<template>
  <div
    class="widgetContainer widgetContainer--scrollable widgetContainer--center bg-white personDetails">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="goToPreviousScreen" />
        <p class="title">
          {{ $t('kyc_NavTitle') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form
        @keyup.enter.native="submitForm()"
        :model="user"
        :rules="rules.user"
        ref="user"
        @submit.prevent.native
        class="wise-form">
        <el-form-item
          class="is-no-asterisk"
          prop="firstName"
          :label="$t('fName')">
          <el-input v-model="user.firstName" />
        </el-form-item>
        <el-form-item
          class="is-no-asterisk"
          prop="lastName"
          :label="$t('lName')">
          <el-input v-model="user.lastName" />
        </el-form-item>
        <el-form-item class="is-no-asterisk" prop="phone" :label="$t('phone')">
          <MobileInput :value="user.phone" @input="mobileNumberUpdate" :show-country-code-selector="true" :autofocus="false" />
        </el-form-item>
        <el-form-item class="is-no-asterisk" prop="email" :label="$t('email')">
          <el-input v-model="user.email" type="email" />
        </el-form-item>
        <el-form-item
          class="is-no-asterisk date-of-birth"
          prop="dateOfBirth"
          :label="$t('dob')">
          <el-date-picker
            format="MM-dd-yyyy"
            :picker-options="datePickerOptions"
            :default-value="minDate"
            v-model="user.dateOfBirth"
            value-format="yyyy-MM-dd"
            type="date" />
        </el-form-item>
        <el-form-item class="is-no-asterisk document-type" :label="$t('Document Type')">
          <el-select v-model="user.idType">
            <el-option v-for="d in documentTypes" :key="d.type" :value="d.type" :label="$t(d.type)" />
          </el-select>
        </el-form-item>
        <el-form-item class="is-no-asterisk" prop="idNumber" :label="$t(user.idType)">
          <SSNInput :id-type="user.idType" :country="country" :value="user.idNumber" @input="ssnChange" :autofocus="false" />
        </el-form-item>
      </el-form>
      <div class="widgetContainer__footer--fixed">
        <el-button type="primary" :disabled="isFormValid" @click="submitForm" class="el-button__brand brand">
          {{ $t('kyc_NavRightButton_Title') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
/** @see {@link https://www.sketch.com/s/d3a40e77-270b-44f7-b432-8276d76b17f4/a/DPEQVda} */
import { mapMutations, mapGetters } from 'vuex';
import { format } from 'date-fns';
import businessMixin from '../../mixins/business';
import SSNInput from '@/components/SSNInput';
import MobileInput from '@/components/MobileInput';
import { alphaAndSpaceRule } from '@/utils/form';
import { getCountryDetailsFromPhoneNumber, getSupportedCountries, getDocumentTypes } from '@/utils/env';

export default {
  name: 'OwnerDetails',
  components: {
    SSNInput,
    MobileInput
  },
  mixins: [businessMixin],
  data() {
    return {
      datePickerOptions: {
        disabledDate(date) {
          const minDate = new Date();
          minDate.setFullYear(minDate.getFullYear() - 18);
          return date > minDate;
        }
      },
      user: {
        firstName: '',
        lastName: '',
        email: '',
        dateOfBirth: '',
        idNumber: '',
        idType: 'ssn',
        phone: ''
      },
      isPhoneNumberValid: false,
      isSsnValid: false,
      rules: {
        user: {
          firstName: alphaAndSpaceRule('First Name', true),
          lastName: alphaAndSpaceRule('Last Name', true),
          phone: [
            {
              required: true,
              message: 'Mobile Number is required',
              trigger: 'blur'
            }
          ],
          email: [
            {
              required: true,
              message: 'Email is required',
              trigger: 'blur'
            },
            {
              type: 'email',
              message: 'Please provide a valid email address',
              trigger: 'blur'
            }
          ],
          dateOfBirth: [
            {
              required: true,
              message: 'Date of birth is required'
            }
          ],
          idNumber: [
            {
              required: true,
              message: 'Tax ID is required'
            }
          ]
        }
      }
    };
  },
  created() {
    if (
      this.getCreatePageOwnersDetail &&
      this.getCreatePageOwnersDetail.person
    ) {
      this.user = this.getCreatePageOwnersDetail.person;
      this.user.idType = this.user.idType || 'ssn';
      if(this.user.phone) {
        this.isPhoneNumberValid = true;
      }
      if(this.user.idNumber) {
        this.isSsnValid = true;
      }
    }
  },
  computed: {
    ...mapGetters('kyb', ['getCreatePageOwnersDetail']),
    ...mapGetters('user', ['getPersonDetails']),
    minDate() {
      const minDate = new Date();
      minDate.setFullYear(minDate.getFullYear() - 18);
      let month = minDate.getMonth() + 1;
      month = ('' + month).length === 1 ? '0' + month : month;
      let date = minDate.getDate();
      date = ('' + date).length === 1 ? '0' + date : date;
      return minDate.getFullYear() + '-' + month + '-' + date;
    },
    country() {
      const details = getCountryDetailsFromPhoneNumber(this.getPersonDetails.phone);
      if(details && details.code)
        return details.code;
      
      return 'US';
    },
    selectedCountry() {
      const supportedCountries = getSupportedCountries();
      return supportedCountries.find(c => c.code === this.country);
    },
    documentTypes() {
      return getDocumentTypes();
    },
    isFormValid() {
      if (
        this.user.firstName &&
        this.user.lastName &&
        this.user.email &&
        this.user.dateOfBirth &&
        this.user.idNumber &&
        this.isSsnValid
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    ...mapMutations('kyb', ['setCreatePageOwnerDetails']),
    ssnChange(v) {
      if(v) {
        this.isSsnValid = v.isValid;
        this.user.idNumber = v.value;
      }
    },
    mobileNumberUpdate(v) {
      if(v) {
        const {value, isValid} = v;
        this.user.phone = value;
        this.isPhoneNumberValid = isValid;
      }
    },
    submitForm() {
      this.$refs.user.validate((valid) => {
        if (!valid) return;
        const user = this.user;
        if (typeof user.dateOfBirth !== 'string')
          user.dateOfBirth = format(user.dateOfBirth, 'yyyy-MM-dd');

        this.setCreatePageOwnerDetails({
          businessId: this.getSelectedBusiness.id,
          person: user
        });
        setTimeout(() => {
          this.$router.push('/kyb/ownerAddress');
        }, 0);
      });
    }
  }
};
</script>

<style lang="scss">
.personDetails {

  .widgetContainer__body {
    overflow-y: auto;
  }
  .date-of-birth {
    display: flex;
    flex-direction: column;
    .el-form-item__label {
      margin-right: auto;
    }
    .el-date-editor {
      min-width: 100%;
    }
  }
  .document-type .el-select {
    width: 100%;
  }
}
</style>
